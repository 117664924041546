// Library Imports
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Grid } from '@mui/material';

// Local import
import { TextInput } from '../../../../components/shared';
import { CustomSwitch } from '../../../../components/shared/CustomSwitch';

const OrganizationInformation = ({
  handleInputChange,
  formData,
  handleSwitch,
  isEdit
}) => {
  return (
    <>
      <Box bgcolor={'white'} borderRadius={'5px'} width={'100%'}>
        {/* Main container  */}
        <Box px={'20px'} pb={'40px'} pt={'25px'}>
          {/* Heading  */}
          <Typography
            component='p'
            fontSize={'18px'}
            color='primary.gray'
            marginBottom={'15px'}
          >
            Add Organization information
          </Typography>

          {/* Form  */}
          <Grid container spacing={2}>
            <Grid item xxs={12} sm={6}>
              <TextInput
                name='title'
                label='Title'
                disabled={isEdit ? true : false}
                placeholder='Title'
                type='text'
                onChange={handleInputChange('organization')}
                value={formData?.organization?.title}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>
            <Grid item xxs={12} sm={6}>
              <TextInput
                name='description'
                label='Description'
                placeholder='Description'
                type='text'
                onChange={handleInputChange('organization')}
                value={formData?.organization?.description}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>

            <Grid item xxs={12} sm={6}>
              <TextInput
                name='address'
                label='Address'
                placeholder='Address'
                type='text'
                onChange={handleInputChange('organization')}
                value={formData?.organization?.address}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>
            <Grid item xxs={12} sm={6}>
              <TextInput
                name='city'
                label='City'
                placeholder='City'
                type='text'
                onChange={handleInputChange('organization')}
                value={formData?.organization?.city}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>

            <Grid item xxs={12} sm={6}>
              <TextInput
                name='state'
                label='State'
                placeholder='State'
                type='text'
                onChange={handleInputChange('organization')}
                value={formData?.organization?.state}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>

            <Grid item xxs={12} sm={6}>
              <TextInput
                name='phone'
                label='Phone Number'
                placeholder='(123) 456-7890'
                type='tel'
                onChange={handleInputChange('organization')}
                value={formData?.organization?.phone}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>

            <Grid item xxs={12} sm={6}>
              <TextInput
                name='zip'
                label='Zip'
                placeholder='zip'
                type='number'
                onChange={handleInputChange('organization')}
                value={formData?.organization?.zip}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>
            <Grid item xxs={12} sm={6}>
              <TextInput
                name='registration_number'
                label='Registration Number'
                placeholder='Registration Number'
                type='number'
                onChange={handleInputChange('organization')}
                value={formData?.organization?.registration_number}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>
            {/* <Grid item xxs={12} sm={6}>
              <Box
                display={'flex'}
                justifyContent={'start'}
                justifyItems={'center'}
                mt={{ xxs: 2, sm: 2.5 }}
                ml={1}
              >
                <CustomSwitch
                  label='Status'
                  active={formData?.organization?.status}
                  handleSwitch={handleSwitch}
                />
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default OrganizationInformation;
