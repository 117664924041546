//************************* Organizations Data Start *******************************//
export const OrganizationsColumnData = [
  { name: 'srNo', title: 'Sr No' },
  { name: 'name', title: 'Name' },
  { name: 'registrationNumber', title: 'Registration Number' },
  { name: 'address', title: 'Address' },
  { name: 'adminName', title: 'Admin Name' },
  { name: 'adminEmail', title: 'Admin Email' },
  { name: 'status', title: 'Status' },
  { name: 'action', title: 'Action' },
];

export const OrganizationsRowData = [
  {
    id: 1,
    srNo: 1,
    name: 'Tech Corp',
    registrationNumber: 'TC123456',
    address: '123 Tech Avenue, San Francisco, CA 94107',
    adminName: 'John Doe',
    adminEmail: 'john.doe@techcorp.com',
    status: true,
    action: 'Edit',
  },
  {
    id: 2,
    srNo: 2,
    name: 'LuxeLocker',
    registrationNumber: 'LL987654',
    address: '789 Luxe Street, Los Angeles, CA 90001',
    adminName: 'Jane Smith',
    adminEmail: 'jane.smith@luxelocker.com',
    status: true,
    action: 'Edit',
  },
  {
    id: 3,
    srNo: 3,
    name: 'GreenTech',
    registrationNumber: 'GT654321',
    address: '456 Green Lane, Seattle, WA 98101',
    adminName: 'Alice Johnson',
    adminEmail: 'alice.johnson@greentech.com',
    status: false,
    action: 'Edit',
  },
  {
    id: 4,
    srNo: 4,
    name: 'City Hospital',
    registrationNumber: 'CH111213',
    address: '900 Lake Drive, Chicago, IL 60611',
    adminName: 'David Brown',
    adminEmail: 'david.brown@cityhospital.com',
    status: true,
    action: 'Edit',
  },
];

export const OrganizationsColumnExtensionsData = [
  { columnName: 'srNo', width: 60 },
  { columnName: 'name', width: 80 },
  { columnName: 'registrationNumber', width: 150 },
  { columnName: 'address', width: 200 },
  { columnName: 'adminName', width: 120 },
  { columnName: 'adminEmail', width: 150 },
  { columnName: 'status', width: 80 },
  { columnName: 'action', width: 100 },
];

//************************* Organizations Data End *******************************//
