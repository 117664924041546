// Library imports
import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Modal from '@mui/material/Modal';

// Icons Import
import CloseIcon from '@mui/icons-material/Close';

// Local Imports
import { CustomButton } from '../Button';

export const CustomModal = ({
  children,
  open,
  close,
  title,
  isDelete,
  primaryButton,
  primaryButtonText,
  handleSave,
  disableSave,
  loading,
  saveButtonSx
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: '10px',
  };
  return (
    <div>
      <Modal open={open} onClose={close}>
        {/* Main container  */}
        <Box sx={style} pt={'10px'} px={'20px'} pb={'10px'}>
          {/* Modal Header  */}
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography component='p' fontSize={'18px'} color='primary.gray'>
              {title}
            </Typography>
            <Box
              component={'div'}
              ml={'auto'}
              sx={{ cursor: 'pointer' }}
              onClick={close}
            >
              <CloseIcon />
            </Box>
          </Box>

          {/* Modal Childern*/}
          <Box
            maxHeight={'600px'}
            maxWidth={{ xxs: '265px', xs: '370px', sm: '450px', md: '600px' }}
            minHeight={'70px'}
            minWidth={{ xxs: '265px', xs: '370px', sm: '450px', md: '600px' }}
            overflow={'auto'}
          >
            {children}
          </Box>

          {/* Modal Footer  */}
          <Box
            display={'flex'}
            flexDirection={{ xxs: 'column-reverse', xs: 'row' }}
            justifyContent={'end'}
            alignItems={'center'}
            gap={2}
          >
            <CustomButton
              sx={{ width: { xxs: '100%', xs: 'fit-content' } }}
              onClick={close}
              variant={'outlined'}
            >
              <Typography fontSize={'14px'}>Cancel</Typography>
            </CustomButton>

            <CustomButton
              sx={{
                width: { xxs: '100%', xs: 'fit-content' },
                bgcolor: isDelete ? 'primary.danger' : 'primary.main',
                display: primaryButton === false ? 'none' : 'block',
                boxShadow: 'none',
                ...saveButtonSx,
              }}
              onClick={handleSave}
              variant={'contained'}
              disabled={disableSave}
              loading={loading}
            >
              <Typography component={'p'} lineHeight='16px' fontSize={'14px'}>
                {isDelete
                  ? primaryButtonText
                    ? primaryButtonText
                    : 'Delete'
                  : primaryButtonText
                  ? primaryButtonText
                  : 'Save'}
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
