// Library Imports
import React, { useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Local imports
import {
  CustomButton,
  TextInput,
  CustomDivider,
  CustomCheckbox,
} from '../../../components/shared';

const AddRole = () => {
  const [formData, setFormData] = useState({
    role: '',
    rolePermission: {
      administratorAccess: false,
      campaigns: { read: false, write: false, create: false },
      tasks: { read: false, write: false, create: false },
      fundraising: { read: false, write: false, create: false },
      analytics: { read: false, write: false, create: false },
      media: { read: false, write: false, create: false },
    },
  });

  const rolePermissionData = [
    {
      role: 'Administrator Access',
      permissions: [
        {
          value: 'all',
          label: 'Select all',
        },
      ],
    },
    {
      role: 'Campaigns',
      permissions: [
        {
          value: 'read',
          label: 'Read',
        },
        {
          value: 'write',
          label: 'Write',
        },
        {
          value: 'create',
          label: 'Create',
        },
      ],
    },
    {
      role: 'Tasks',
      permissions: [
        {
          value: 'read',
          label: 'Read',
        },
        {
          value: 'write',
          label: 'Write',
        },
        {
          value: 'create',
          label: 'Create',
        },
      ],
    },
    {
      role: 'Fundraising',
      permissions: [
        {
          value: 'read',
          label: 'Read',
        },
        {
          value: 'write',
          label: 'Write',
        },
        {
          value: 'create',
          label: 'Create',
        },
      ],
    },
    {
      role: 'Analytics',
      permissions: [
        {
          value: 'read',
          label: 'Read',
        },
        {
          value: 'write',
          label: 'Write',
        },
        {
          value: 'create',
          label: 'Create',
        },
      ],
    },
    {
      role: 'Media',
      permissions: [
        {
          value: 'read',
          label: 'Read',
        },
        {
          value: 'write',
          label: 'Write',
        },
        {
          value: 'create',
          label: 'Create',
        },
      ],
    },
  ];

  const navigate = useNavigate();

  const handleCheckbox = (event, role, permission) => {
    const { checked } = event.target;

    if (role === 'administrator access') {
      setFormData((prev) => ({
        ...prev,
        rolePermission: {
          administratorAccess: checked,
          campaigns: { read: checked, write: checked, create: checked },
          tasks: { read: checked, write: checked, create: checked },
          fundraising: { read: checked, write: checked, create: checked },
          analytics: { read: checked, write: checked, create: checked },
          media: { read: checked, write: checked, create: checked },
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        rolePermission: {
          ...prev.rolePermission,
          administratorAccess: false,
          [role]: {
            ...prev.rolePermission[role],
            [permission]: checked,
          },
        },
      }));
    }
  };

  const handleInput = (e) => {
    setFormData((prev) => ({
      ...prev,
      role: e.target.value,
    }));
  };

  // Handle submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', formData);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      style={{ height: 'calc(100vh - 63px)' }}
      component='form'
      onSubmit={handleSubmit}
    >
      <Box
        backgroundColor='white'
        borderRadius='5px'
        mx='20px'
        overflow='auto'
        maxHeight='565px'
        mt='25px'
      >
        <Box mx='20px' mb='15px' p='20px 0px'>
          {/* Heading  */}
          <Typography
            variant='h4'
            color='primary.darkGray'
            fontSize='18px'
            fontWeight={500}
          >
            Add Role
          </Typography>
          <Typography component='p' color='primary.gray' fontSize='12px'>
            Adding a role to the role list
          </Typography>

          {/* Body  */}
          <Box
            display='flex'
            flexDirection='column'
            gap={{ xxs: 0, xm: 1 }}
            py='20px'
          >
            {/* Add Role Input  */}
            <Grid container spacing={{ xm: 2 }}>
              <Grid item xxs={12} sm={9} base={5}>
                <TextInput
                  name='roleName'
                  label='Role name'
                  placeholder='Enter a role name'
                  type='text'
                  onChange={handleInput}
                  value={formData.role}
                />
              </Grid>
            </Grid>

            {/* Role Permissions */}
            <Typography
              component='p'
              fontSize='14px'
              color='primary.gray'
              mt='20px'
              fontWeight={500}
              mb={{ xxs: '10px', xm: 0 }}
            >
              Role Permissions
            </Typography>

            {rolePermissionData.map(({ role, permissions }, index) => (
              <Box key={index} width='100%'>
                <Grid container spacing={{ xm: 1 }}>
                  {/* ModuleName  */}
                  <Grid item xxs={12} sm={3} base={2}>
                    <Typography
                      component='p'
                      fontSize='12px'
                      color='primary.gray'
                      marginBottom={{ sx: '15px' }}
                      marginTop='10px'
                      fontWeight={500}
                    >
                      {role}
                    </Typography>
                  </Grid>

                  {/* Permission  */}
                  <Grid item xxs={12} sm={5} base={3}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      {permissions.map(({ value, label }) => {
                        const isChecked =
                          role === 'Administrator Access'
                            ? formData.rolePermission.administratorAccess
                            : formData.rolePermission[role.toLowerCase()]?.[
                                value
                              ] || false;

                        return (
                          <Box key={value}>
                            <CustomCheckbox
                              value={value}
                              checked={isChecked}
                              onChange={(e) =>
                                handleCheckbox(e, role.toLowerCase(), value)
                              }
                              label={label}
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  fontSize: '12px',
                                },
                              }}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                </Grid>
                <CustomDivider />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Footer  */}
      <Box
        bgcolor='white'
        height='62px'
        display='flex'
        justifyContent='end'
        alignItems='center'
        px='20px'
        py='10px'
        gap={2}
      >
        <CustomButton
          onClick={() => navigate('/roles-and-permissions')}
          variant='outlined'
        >
          Cancel
        </CustomButton>
        <CustomButton
          type='submit'
          variant='contained'
          sx={{ width: { xxs: '100%', xs: '200px' } }}
        >
          Create Role
        </CustomButton>
      </Box>
    </Box>
  );
};

export default AddRole;
