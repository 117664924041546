// Library imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Local imports
import axiosInstance from '../network/api/http';
import endPoints from '../network/api/constant';

// Initial State
const initialState = { response: null, loading: false, error: null };

// subsciptionListing Api
export const subsciptionListing = createAsyncThunk(
  'subsciptionListing',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(endPoints.subscriptionsListing);
      return response.data;
    } catch (error) {
      console.error('Add failed:', error);
      if (error.response) {
        return rejectWithValue(error.response.data || 'An error occurred');
      } else {
        return rejectWithValue('Network error: Please check your connection.');
      }
    }
  }
);

const subsciptionListingSlice = createSlice({
  name: 'subsciptionListing',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(subsciptionListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(subsciptionListing.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(subsciptionListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to log in';
      });
  },
});

export default subsciptionListingSlice.reducer;
