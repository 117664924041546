// Library imports
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Local imports
import authReducer from './reducers/authReducers';
import subsciptionListingSlice from './reducers/subscriptionsListing';
import organizationListingSlice from './reducers/organizationListing';
import forgotPasswordSlice from './reducers/forgotPassword';
import resetPasswordSlice from './reducers/resetPassword';
import addOrganizationSlice from './reducers/addOrganizationReducer';
import  updateOrganizationSlice  from './reducers/updateOrganization';

// Define the persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  sub: subsciptionListingSlice,
  organizationListing: organizationListingSlice,
  forgetPassword: forgotPasswordSlice,
  resetPassword: resetPasswordSlice,
  addOrganization: addOrganizationSlice,
  updateOrganization: updateOrganizationSlice,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Create a persistor
export const persistor = persistStore(store);
