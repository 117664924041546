// Library imports
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Box, Typography } from '@mui/material';

export const CustomAvatar = ({ sideMenu, userName, role, reverse }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={reverse ? 'row-reverse' : 'row'}
      gap={1}
      justifyContent='center'
      alignItems='center'
    >
      <Box display={{xxs:sideMenu ? 'flex' : 'none', sm:'flex'}} flexDirection='column'>
        <Typography fontSize='16px' component={'p'} fontFamily={'Montserrat'}>
          {userName}
        </Typography>
        <Typography
          mt={'-2px'}
          fontSize={'10px'}
          fontWeight={400}
          variant='base'
          color='primary.darkGray'
          fontFamily={'Montserrat'}
        >
          {role}
        </Typography>
      </Box>

      <Avatar
        alt={userName}
        src='/static/images/avatar/1.jpg'
        sx={{ width: 36, height: 36, bgcolor: 'secondary.main' }}
      />
    </Box>
  );
};
