// Library imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


// Local imports
import axiosInstance from '../network/api/http';
import endPoints from '../network/api/constant';

// Initial State
const initialState = { response: null, loading: false, error: null };

// addOrganization Api
export const addOrganization = createAsyncThunk(
  'addOrganization',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        endPoints.addOrganization,
        data
      );
      return response.data;
    } catch (error) {
      console.error('Add failed:', error);
      if (error.response) {
        return rejectWithValue(error.response.data || 'An error occurred');
      } else {
        return rejectWithValue('Network error: Please check your connection.');
      }
    }
  }
);

const addOrganizationSlice = createSlice({
  name: 'addOrganization',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addOrganization.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(addOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to log in';
      });
  },
});

export default addOrganizationSlice.reducer;
