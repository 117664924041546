// Library import
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Local import
import { CustomButton } from '../../components/shared';

const RoleAndPermission = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box mt={'15px'} px={{ xxs: '10px', base: '20px' }}>
        <Box display={'flex'} justifyContent={'end'}>
          <CustomButton
            variant={'contained'}
            onClick={() => navigate('/roles-and-permissions/add-role')}
          >
            <Typography>Add Role</Typography>
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default RoleAndPermission;
