// Library imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Local imports
import axiosInstance from '../network/api/http';
import endPoints from '../network/api/constant';

// Initial State
const initialState = { response: null, loading: false, error: null };

// updateOrganization Api
export const updateOrganization = createAsyncThunk(
  'updateOrganization',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        endPoints.updateOrganization,
        data
      );
      return response.data;
    } catch (error) {
      console.error('update failed:', error);
      if (error.response) {
        return rejectWithValue(error.response.data || 'An error occurred');
      } else {
        return rejectWithValue('Network error: Please check your connection.');
      }
    }
  }
);

const updateOrganizationSlice = createSlice({
  name: 'updateOrganization',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateOrganization.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to log in';
      });
  },
});

export default updateOrganizationSlice.reducer;
