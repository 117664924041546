// Library import
import React from 'react';
import { Navigate } from 'react-router-dom';

// Icons imports
import {
  DashboardIcon,
  RoleAndPermissionIcon,
  OrganizationIcon,
} from '../assets/Icons';


// Local import
import { AuthLayout, PanelLayout } from '../components/shared';
import Login from '../panel/Auth/Login';
import SignUp from '../panel/Auth/SignUp';
import OrganizationsListing from '../panel/Organizations/OrganizationsListing';
import AddOrganizations from '../panel/Organizations/AddOrganizations';
import RoleAndPermission from '../panel/RoleAndPermission';
import ForgotPassword from '../panel/Auth/ForgotPassword';
import ResetPassword from '../panel/Auth/ResetPassword';
import { Error404 } from '../components/shared';
import AddRole from '../panel/RoleAndPermission/AddRole';

// SIDE MENU ROUTES
export const sideMenuRoutes = [
  {
    path: '/dashboard',
    moduleName: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    path: '/organizations',
    moduleName: 'Organizations',
    icon: <OrganizationIcon />,
  },
  // {
  //   path: '/roles-and-permissions',
  //   moduleName: 'Roles and Permissions',
  //   icon: <RoleAndPermissionIcon />,
  // },
];

// PRIVATE ROUTES
export const privateRoutes = [
  {
    path: '/dashboard',
    moduleName: 'Dashboard',
    element: <PanelLayout>Dashboard</PanelLayout>,
  },

  {
    path: '/organizations',
    moduleName: 'Organizations',
    element: (
      <PanelLayout>
        <OrganizationsListing />
      </PanelLayout>
    ),
    child: [
      {
        path: '/organizations/organization',
        moduleName: 'Organizations',
        element: (
          <PanelLayout>
            <AddOrganizations />
          </PanelLayout>
        ),
      },
    ],
  },

  {
    path: '/roles-and-permissions',
    moduleName: 'Role and Permission',
    element: (
      <PanelLayout>
        <RoleAndPermission />
      </PanelLayout>
    ),
    child: [
      {
        path: '/roles-and-permissions/add-role',
        moduleName: 'Add Role',
        element: (
          <PanelLayout>
            <AddRole />
          </PanelLayout>
        ),
      },
    ],
  },
];

// PUBLIC ROUTES
export const publicRoutes = [
  {
    path: '/',
    moduleName: 'Base',
    element: <Navigate to='/login' replace />,
  },
  {
    path: '/login',
    moduleName: 'Login',
    element: (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
  },
  {
    path: '/signup',
    moduleName: 'Signup',
    element: (
      <AuthLayout>
        <SignUp />
      </AuthLayout>
    ),
  },
  {
    path: '/forgot-password',
    moduleName: 'Forgot password',
    element: (
      <AuthLayout>
        <ForgotPassword />
      </AuthLayout>
    ),
  },
  {
    path: '/reset-password',
    moduleName: 'Reset Password',
    element: (
      <AuthLayout>
        <ResetPassword />
      </AuthLayout>
    ),
  },
  {
    path: '*',
    element: <Error404 />,
  },
];
