import React, { useState } from 'react';
import { TextField, Box, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

export const TextInput = ({
  name,
  value,
  onChange,
  disabled,
  id,
  type,
  size = 'small',
  label,
  placeholder,
  error,
  forgotPassword,
  sx,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleMouseUpPassword = (event) => event.preventDefault();

  const handleNumericChange = (e) => {
    const regex = /^[0-9]*$/; // Only allow numbers
    if (regex.test(e.target.value) || e.target.value === '') {
      onChange(e);
    }
  };

  return (
    <Box>
      {/* Label */}
      <Box display={'flex'} justifyContent={'space-between'} gap={2}>
        <Typography
          htmlFor={id}
          fontSize={'12px'}
          color={error ? 'primary.danger' : 'primary.gray'}
          textTransform={'capitalize'}
          alignSelf={'end'}
        >
          {label}
        </Typography>
        {type === 'password'
          ? forgotPassword && (
              <Typography
                fontSize={'12px'}
                color='primary.main'
                textAlign={'right'}
                onClick={() => console.log('Forgot Password')}
              >
                Forgot Password?
              </Typography>
            )
          : null}
      </Box>

      {/* Input Field */}
      <TextField
        id={id}
        size={size}
        // type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        type={
          type === 'password'
            ? showPassword
              ? 'text'
              : 'password'
            : type === 'number'
            ? 'text'
            : type
        }
        placeholder={placeholder}
        autoComplete={type === 'password' ? 'password' : ''}
        name={name}
        value={value}
        // onChange={!disabled ? onChange : null}
        onChange={
          type === 'number' && !disabled ? handleNumericChange : onChange
        }
        disabled={disabled}
        slotProps={{
          input: {
            endAdornment:
              type === 'password' ? (
                <InputAdornment position='start'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge='end'
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ fontSize: '22px' }} />
                    ) : (
                      <Visibility sx={{ fontSize: '22px' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ) : null,
          },
        }}
        sx={{
          width: '100%',
          cursor: disabled ? 'not-allowed' : 'text',
          '& .MuiInputBase-input.Mui-disabled': {
            cursor: 'not-allowed',
          },
          input: {
            '&::placeholder': {
              fontSize: '14px',
            },
          },
          '& label.Mui-focused': {
            color: 'black',
          },
          '& .MuiOutlinedInput-root': {
            paddingRight: '5px',
            '& fieldset': {
              borderColor: error ? 'primary.danger' : 'primary.lightGray',
            },
            '&:hover fieldset': {
              borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
              borderWidth: '1px',
              borderColor: 'black',
            },
          },
          ...sx,
        }}
      />
      {error ? (
        <Typography fontSize={'12px'} color='primary.danger'>
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};
