// StaggingBaseURLS
export const baseURL = 'https://camphq-api.testerp.co/api';

// EndPoints
const endPoints = {
  /***********************  End Points Start *************************/

  // Login User
  login: `${baseURL}/login`,

  // Add Organization
  addOrganization: `${baseURL}/organization/store`,

  // Add Organization
  updateOrganization: `${baseURL}/organization/update-status`,

  // Subscription listing
  subscriptionsListing: `${baseURL}/subscriptions`,

  // Organization listing
  organizationListing: `${baseURL}/organization/list`,

  // Subscription listing
  forgotPassword: `${baseURL}/forgot-password`,

  // Subscription listing
  resetPassword: `${baseURL}/reset-password`,
  /***********************  End Points End *************************/
};

export default endPoints;
